<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Discount Sales Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.discount.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content rounded-5 shadow-0">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table header-fixed">
                      <thead class="md-card-header sticky shadow-0">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Bill No</div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Card No</div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Discount Type
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Gross Amount
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Discount Amount
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Total</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="listDiscountReportList.length > 0"
                        :set="
                          ((grossAmtGrandTotal = 0),
                          (discAmtGrandTotal = 0),
                          (totalGrandTotal = 0))
                        "
                      >
                        <template
                          v-for="(promo, index) in listDiscountReportList"
                        >
                          <template>
                            <span
                              :set="
                                ((grossAmt = 0), (discAmt = 0), (total = 0))
                              "
                              :key="`obj_head_${index}`"
                              class="head_bg"
                              v-if="
                                index == 0 ||
                                (index != 0 &&
                                  listDiscountReportList[index] &&
                                  listDiscountReportList[index - 1] &&
                                  listDiscountReportList[index - 1]
                                    .order_date &&
                                  listDiscountReportList[index].order_date &&
                                  listDiscountReportList[index - 1]
                                    .order_date !=
                                    listDiscountReportList[index].order_date)
                              "
                            >
                            </span>
                            <tr :key="`cash_${index}`">
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ promo.timestamp | timeStamp }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ promo.bill_no }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    (promo.first_name ? promo.first_name : "") +
                                    " " +
                                    (promo.last_name ? promo.last_name : "")
                                  }}
                                </div>
                              </td>
                              <td
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('card')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    promo.id_proof_number &&
                                    promo.id_proof_number != null
                                      ? promo.id_proof_number
                                      : "NA"
                                  }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ getDiscountType(promo) }}
                                </div>
                              </td>
                              <td
                                :set="
                                  (grossAmt += parseFloat(
                                    promo.total_actual_price
                                      ? promo.total_actual_price
                                      : 0
                                  ))
                                "
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('credit')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ promo.total_actual_price | toCurrency }}
                                </div>
                              </td>
                              <td
                                :set="
                                  (discAmt += parseFloat(
                                    promo.total_actual_price
                                      ? promo.total_actual_price -
                                          promo.order_total
                                      : 0
                                  ))
                                "
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('voucher')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    (promo.total_actual_price -
                                      promo.order_total)
                                      | toCurrency
                                  }}
                                </div>
                              </td>
                              <td
                                :set="
                                  (total += parseFloat(
                                    promo.order_total ? promo.order_total : 0
                                  ))
                                "
                                class="md-table-cell"
                                v-if="
                                  checkTableColumnVisibility('complementary')
                                "
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    promo.order_total
                                      ? promo.order_total
                                      : 0 | toCurrency
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr
                              class="md-table-row grand_total_bg"
                              :key="`order_${index}`"
                              v-if="
                                (index == 0 &&
                                  listDiscountReportList[index] &&
                                  listDiscountReportList[index + 1] &&
                                  listDiscountReportList[index].order_date &&
                                  listDiscountReportList[index].order_date !=
                                    listDiscountReportList[index + 1]
                                      .order_date) ||
                                index == listDiscountReportList.length - 1 ||
                                (index != 0 &&
                                  listDiscountReportList[index] &&
                                  listDiscountReportList[index + 1] &&
                                  listDiscountReportList[index].order_date &&
                                  listDiscountReportList[index].order_date !=
                                    listDiscountReportList[index + 1]
                                      .order_date)
                              "
                              :set="
                                ((grossAmtGrandTotal += parseFloat(
                                  grossAmt ? grossAmt : 0
                                )),
                                (discAmtGrandTotal += parseFloat(
                                  discAmt ? discAmt : 0
                                )),
                                (totalGrandTotal += parseFloat(
                                  total ? total : 0
                                )))
                              "
                            >
                              <td
                                class="md-table-cell"
                                v-if="checkTableColumnVisibility('cashier')"
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  Total
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                ></div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                ></div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                ></div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                ></div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ grossAmt | toCurrency }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ discAmt | toCurrency }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ total | toCurrency }}
                                </div>
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template>
                          <tr
                            class="md-table-row grand_total_bg sticky_bottom"
                            :key="`order_${index}`"
                          >
                            <td
                              class="md-table-cell"
                              v-if="checkTableColumnVisibility('cashier')"
                            >
                              <div class="md-table-cell-container text-center">
                                <strong>GRAND TOTAL</strong>
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                              ></div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                              ></div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                              ></div>
                            </td>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                              ></div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ grossAmtGrandTotal | toCurrency }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ discAmtGrandTotal | toCurrency }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ totalGrandTotal | toCurrency }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import BackButton from "@/components/Common/BackButton.vue";

export default {
  components: { BackButton, ExportIcon, SvgIcon },
  data() {
    return {
      listDiscountReportList: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      columnData: [
        { name: "Cashier Name", value: "cashier" },
        { name: "Cash", value: "cash" },
        { name: "Card", value: "card" },
        { name: "Credit", value: "credit" },
        { name: "Voucher", value: "voucher" },
        { name: "Complementary", value: "complementary" },
      ],
      columns: [
        "cashier",
        "cash",
        "card",
        "credit",
        "voucher",
        "complementary",
        "Discount Type",
      ],
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    getDiscountType(promo) {
      let discount = "N/A";
      if (promo.is_product_redeem) {
        discount = "Product Redeem";
      }
      if (promo.discount_type) {
        if (discount != "N/A") {
          discount += ", " + promo.discount_type;
        } else {
          discount = promo.discount_type;
        }
      }
      return discount;
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Discount-Sale-Report-" + this.fileName;
      else this.fileName = "Discount-Sale-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },

    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/discount-sale-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.listDiscountReportList = data;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "discount-sale-report-pdf";
      } else if (type == "excel") {
        link = "discount-sale-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },

    getTotalColspan() {
      let colspan = 7;

      return colspan;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8; /* Set color opacity to 10% */
  z-index: 1;
  padding: 10px 4px;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
.orange-text {
  color: #ff9800 !important;
  caret-color: #ff9800 !important;
}
</style>
